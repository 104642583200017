import { navigate, graphql } from "gatsby";

import { Center, Container, Spacer, Text, Button, Box } from "@chakra-ui/react";
import Layout from "../components/Layout";
import Anchor from "../components/Anchor";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { SEO } from "../components/SEO";

const IndexPage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <SEO title={t("common.home")} />
      <Spacer />
      <Box w="full" bg="whiteAlpha.800" padding={5} rounded="xl">
        <Container maxW="xl" minHeight="40%">
          <Text fontSize="2xl" margin="20px" align="center">
            {t("IndexPage.i_am_tracy")}
          </Text>
          <Text fontSize="md" margin="20px" align="center">
            {t("IndexPage.service_description")}
          </Text>

          <Text fontSize="2xl" margin="20px" align="center">
            {t("IndexPage.call_to_action")}
          </Text>

          <Anchor to="/how-this-works">
            <Text fontSize="md" margin="20px" align="center">
              {t("IndexPage.how_does_this_work")}
            </Text>
          </Anchor>

          <Center>
            <Button
              size="lg"
              margin="20px"
              onClick={() => {
                navigate("/notify-someone");
              }}
              _hover={{
                transform: "translateY(-2px)",
                boxShadow: "lg",
              }}
              data-cy="btn-notify-someone"
            >
              {t("IndexPage.call_to_action2")}
            </Button>
          </Center>
        </Container>
      </Box>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
